import androidx.compose.animation.*
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ThumbDown
import androidx.compose.material.icons.filled.ThumbUp
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.traceEventEnd
import androidx.compose.ui.Alignment
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.launch

@Composable
fun VoteWidget() {
    val scope = rememberCoroutineScope()

    Row(
        horizontalArrangement = Arrangement.spacedBy(2.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        VoteButton(
            icon = Icons.Filled.ThumbDown,
            isActive = AppManager.isCurrentPositiveVote == false,
            contentDescription = "Nie kurwa, dawaj śmiecu pracować na farmie bawełny",
            onClick = { AppManager.vote(false) }
        )

        Row(
            horizontalArrangement = Arrangement.spacedBy(1.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            AnimatedContent(
                targetState = AppManager.voteCount,
                transitionSpec = {
                    if (targetState > initialState) {
                        slideIn { objSize -> IntOffset(0, objSize.height) } togetherWith
                                slideOut { objSize -> IntOffset(0, -objSize.height) }
                    } else {
                        slideIn { objSize -> IntOffset(0, -objSize.height) } togetherWith
                                slideOut { objSize -> IntOffset(0, objSize.height) }
                    }
                }
            ) { votes ->
                Text("$votes")
            }

            Text("/69")
        }

        VoteButton(
            icon = Icons.Filled.ThumbUp,
            isActive = AppManager.isCurrentPositiveVote == true,
            contentDescription = "Umm, Mniam mniam, umrze kurwa śmieciu",
            onClick = { AppManager.vote(true) }
        )
    }
}