@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package portfolio.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.ExperimentalResourceApi

@ExperimentalResourceApi
private object Drawable0 {
  public val chomuk: DrawableResource = org.jetbrains.compose.resources.DrawableResource(
        "drawable:chomuk",
          setOf(
            org.jetbrains.compose.resources.ResourceItem(setOf(), "drawable/chomuk.png"),
          )
      )
}

@ExperimentalResourceApi
internal val Res.drawable.chomuk: DrawableResource
  get() = Drawable0.chomuk
