import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ThumbDown
import androidx.compose.material.icons.filled.ThumbUp
import androidx.compose.material3.*
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.ExperimentalResourceApi
import org.jetbrains.compose.resources.painterResource
import portfolio.composeapp.generated.resources.Res
import portfolio.composeapp.generated.resources.chomuk

@OptIn(ExperimentalResourceApi::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun App() = MaterialTheme(
    colorScheme = darkColorScheme()
) {
    val scope = rememberCoroutineScope()
    val windowWidthSizeClass = calculateWindowSizeClass().widthSizeClass

    LaunchedEffect(Unit) {
        AppManager.start(scope)
    }

    Surface(
        modifier = Modifier.fillMaxSize()
    ) {
        Box(
            modifier = Modifier.fillMaxSize(),
            contentAlignment = Alignment.Center
        ) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.spacedBy(10.dp)
            ) {
                Text(
                    text = "Vote for",
                    fontWeight = FontWeight.Bold,
                    fontSize = 100.sp
                )

                Row(
                    horizontalArrangement = Arrangement.spacedBy(3.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(
                        painter = painterResource(Res.drawable.chomuk),
                        contentDescription = "hujek jebany",
                        modifier = Modifier.clip(CircleShape)
                            .size(75.dp),
                    )

                    Text(
                        text = "'s lethal injection",
                        fontWeight = FontWeight.Bold,
                        fontSize = when (windowWidthSizeClass) {
                            WindowWidthSizeClass.Expanded -> 53.sp
                            WindowWidthSizeClass.Medium -> 34.sp
                            WindowWidthSizeClass.Compact -> 20.sp
                            else -> 20.sp
                        },
                        softWrap = true
                    )
                }

                Text(
                    text = "On -420, he'll become the owner of \"Hakerzy wysznera\"",
                    fontSize = 10.sp,
                    color = Color.Gray
                )

                Box(
                    modifier = Modifier.height(75.dp)
                )

                VoteWidget()
            }
        }
    }
}