import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.skiko.kotlinBackend
import kotlin.time.Duration.Companion.seconds

object AppManager {
    var voteCount: Int by mutableStateOf(0)
    var isCurrentPositiveVote: Boolean? by mutableStateOf(null)

    private val httpClient = HttpClient {
        install(Logging) {
            logger = Logger.EMPTY
            level = LogLevel.NONE
        }

        install(DefaultRequest) {
            url("https://chomikick.duckdns.org:6911")
            userAgent("SIGMOS 1.0 / jestem gejem")
        }

        install(ContentNegotiation) {
            json(
                json = Json {
                    prettyPrint = true
                }
            )
        }
    }

    suspend fun start(coroutineScope: CoroutineScope) = coroutineScope.launch {
        while (true) {
            delay(1.seconds)
            try {
                val data = httpClient.get("").body<FetchData>()
                voteCount = data.voteRatio
                isCurrentPositiveVote = data.isCurrentVotePositive
            } catch (_: Throwable) { }
        }
    }

    suspend fun vote(positive: Boolean) = try {
        if (isCurrentPositiveVote == null) {
            if (positive) voteCount++ else voteCount--
            isCurrentPositiveVote = positive
        } else if (isCurrentPositiveVote != positive) {
            if (positive) voteCount += 2 else voteCount -= 2
            isCurrentPositiveVote = positive
        } else {
            if (positive) voteCount-- else voteCount++
            isCurrentPositiveVote = null
        }

        httpClient.post("") {
            setBody(
                body = if (positive) "+" else "-"
            )
        }
    } catch (_: Throwable) { }
}