import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
fun main() = onWasmReady {
    CanvasBasedWindow(
        title = "Vote for chomikuj pl's lethal injection",
        canvasElementId = "ComposeTarget"
    ) { App() }
}