import androidx.compose.animation.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ThumbDown
import androidx.compose.material3.FilledIconButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.IconButtonColors
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.graphics.vector.ImageVector
import kotlinx.coroutines.launch

@Composable
fun VoteButton(
    icon: ImageVector,
    isActive: Boolean,
    contentDescription: String,
    onClick: suspend () -> Unit
) {
    val scope = rememberCoroutineScope()

    AnimatedContent(
        targetState = isActive,
        transitionSpec = { fadeIn() togetherWith fadeOut() }
    ) { isCurrentlyActive ->
        if (isCurrentlyActive) {
            FilledIconButton(
                onClick = { scope.launch { onClick() } }
            ) {
                Icon(
                    imageVector = icon,
                    contentDescription = contentDescription
                )
            }
        } else {
            IconButton(
                onClick = { scope.launch { onClick() } }
            ) {
                Icon(
                    imageVector = icon,
                    contentDescription = contentDescription
                )
            }
        }
    }
}